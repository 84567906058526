import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';

import { paramsByBrand } from './constants/param';
import { CREATE_CHECKOUT_SESSION_URL } from './constants';
import { getDemoPayload } from './utils';
import Layout from './components/Layout';
import Loading from './components/Loading';
import HeaderCenter from './assets/header-center.svg';

import styles from './Home.module.scss';

const getDesktopMaxWidth = () => {
  if (isTablet) {
    return '95%';
  }

  if (isMobileOnly) {
    return '100%';
  }

  return '1200px';
};

const restartHandler = (e) => {
  if (e.data === 'restart') {
    console.log('Restarting demo ....');

    window?.location?.reload();
  }

  return false;
};

const Home = () => {
  const location = useLocation();
  const [checkoutURL, setCheckoutURL] = useState();
  const view = useSelector((state) => state.ui.view);
  const { isCouponAppliedAutomatically } = useSelector((state) => state.option);
  const params = parse(location.search);
  const tailoredParams = {
    ...paramsByBrand[params.brand],
    ...params,
  };

  const paramString = Object.keys(tailoredParams)
    .filter((k) => !!tailoredParams[k])
    .map((k) => `${k}=${tailoredParams[k]}`)
    .join('&');

  useEffect(() => {
    fetch(CREATE_CHECKOUT_SESSION_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        getDemoPayload({
          brand: params.brand,
          options: isCouponAppliedAutomatically
            ? { promotionCode: 'SPRINGSALE2022' }
            : {},
        })
      ),
    })
      .then((res) => res.json())
      .then((session) => {
        setCheckoutURL(`${session.checkoutURL}&${paramString}`);
      });
  }, [paramString, params.brand, isCouponAppliedAutomatically]);

  useEffect(() => {
    window.addEventListener('message', restartHandler);

    return () => {
      window.removeEventListener('message', restartHandler);
    };
  }, []);

  useEffect(() => {
    if (checkoutURL) {
      console.log(checkoutURL);
    }
  }, [checkoutURL]);

  return (
    <Layout>
      <div
        className={styles.content}
        style={{
          maxWidth: view === 'mobile' ? '400px' : getDesktopMaxWidth(),
          borderRadius: view === 'mobile' ? 32 : 8,
        }}
      >
        <header className={styles['address-bar']}>
          <img src={HeaderCenter} alt="" width={280} height={20} />
        </header>
        {!checkoutURL ? (
          <Loading />
        ) : (
          <iframe
            className={styles.iframe}
            title="Smartpay | Checkout"
            src={checkoutURL}
            frameBorder={0}
            allowpaymentrequest="true"
          />
        )}
      </div>
    </Layout>
  );
};

export default Home;
